import { gql } from '@apollo/client'

export const CREATE_SCENARIO_REQUEST = gql`
    mutation CreateScenarioRequest($scenarioDescription: String)
    {
        createRequest(scenarioDescription: $scenarioDescription)
        {
            success
            errors
            scenarioId
        }
    }`

import React from 'react';

import ScenarioItem from './ScenarioItem';

const ScenarioList = ({ scenarios }) => {

    return (
        <div className="space-y-4">
            {scenarios.length === 0 ? (
                <div className="text-gray-600 text-center py-8">No scenarios found</div>
            ) : (
                scenarios.map((scenario) => (
                    <ScenarioItem
                        key={scenario._id}
                        scenario={scenario} />
                ))
            )}
        </div>
    );
};

export default ScenarioList;
import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ScenarioItem = ({ scenario }) => {
    const navigate = useNavigate();

    const getStatusColor = (status) => {
        const colors = {
            'In Progress': 'bg-yellow-100 text-yellow-800',
            'Ready': 'bg-green-100 text-green-800',
            'Failed': 'bg-red-100 text-red-800',
            'Pending': 'bg-gray-100 text-gray-800'
        };
        return colors[status] || 'bg-gray-100 text-gray-800';
    };

    const handleClick = () => {
        navigate(`/scenario/${scenario._id}`);
    };

    const displayText = scenario.description || scenario.seed || 'No description available';

    return (
        <div
            key={scenario.id}
            className="bg-white p-4 rounded-lg border border-gray-200 hover:border-blue-300 transition-colors cursor-pointer"
            onClick={handleClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleClick();
                }
            }}
        >
            <div className="flex items-start justify-between">
                <div className="space-y-1 flex-1">
                    <div className="flex items-center gap-2">
                        <h4 className="font-medium text-lg">
                            {scenario.name || 'Unnamed Scenario'}
                        </h4>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(scenario.status)}`}>
                            {scenario.status}
                        </span>
                    </div>
                    <p className="text-gray-600 line-clamp-2">{displayText}</p>
                    <div className="flex items-center gap-4 text-sm text-gray-500">
                        <div className="flex items-center gap-1">
                            <Calendar className="w-4 h-4" />
                            <span>{scenario.currentDateTime}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScenarioItem;
import '../output.css'

import React, { useState } from 'react';
import { UserCircle, Book } from 'lucide-react';

import { useAuth0 } from "@auth0/auth0-react";

import LogoutButton from '../Profile/LogoutButton';
import LoginButton from '../Profile/LoginButton';
import Profile from '../Profile/Profile';

import CreateScenarioForm from './CreateScenarioForm';
import UserScenarios from '../Queries/GetUserScenarios';

const UserProfileLayout = () => {
    const [activeSection, setActiveSection] = useState('scenarios');

    const {
        user,
        isAuthenticated,
    } = useAuth0();

    const ProfileSection = () => (
        <div className="p-6">
            {!isAuthenticated && (
                <LoginButton />
            )}
            {isAuthenticated && (
                <div>
                    <h2 className="text-2xl font-bold mb-6">Profile</h2>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Username</label>
                            <div className="mt-1 text-lg">{user.name}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const ScenariosSection = () => (
        <div className="p-6 space-y-8">
            <h2 className="text-2xl font-bold">Scenarios</h2>
            {!isAuthenticated && (
                <LoginButton />
            )}
            {isAuthenticated && (
                <div>
                    <CreateScenarioForm />
                    <div className="mt-8">
                        <h3 className="text-lg font-semibold mb-4">Your Scenarios</h3>
                        <UserScenarios userId={user.sub} />
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="flex h-screen bg-gray-50">
            {/* Sidebar */}
            <div className="w-64 bg-white border-r border-gray-200">
                <div className="p-6">
                    {isAuthenticated && (
                        <div className="flex items-center space-x-3 mb-8">
                            <Profile className="w-10 h-10 text-gray-400" />
                        </div>
                    )}

                    <nav className="space-y-1">
                        <button
                            onClick={() => setActiveSection('scenarios')}
                            className={`w-full flex items-center space-x-3 px-4 py-2 rounded-md ${activeSection === 'scenarios'
                                ? 'bg-blue-50 text-blue-700'
                                : 'text-gray-700 hover:bg-gray-50'
                                }`}
                        >
                            <Book className="w-5 h-5" />
                            <span>Scenarios</span>
                        </button>

                        <button
                            onClick={() => setActiveSection('profile')}
                            className={`w-full flex items-center space-x-3 px-4 py-2 rounded-md ${activeSection === 'profile'
                                ? 'bg-blue-50 text-blue-700'
                                : 'text-gray-700 hover:bg-gray-50'
                                }`}
                        >
                            <UserCircle className="w-5 h-5" />
                            <span>Profile</span>
                        </button>

                        <LogoutButton
                            className="w-full flex items-center space-x-3 px-4 py-2 rounded-md text-gray-700 hover:bg-gray-50"
                        />
                    </nav>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1">
                {activeSection === 'profile' ? <ProfileSection /> : <ScenariosSection />}
            </div>
        </div>
    );
};

export default UserProfileLayout;
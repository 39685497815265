//import 'bootswatch/dist/slate/bootstrap.min.css';
import './App.css';
import './output.css'
import Overworld from './Queries/GetOverworld';
import Location from './Queries/GetLocation';
import Conversation from './Queries/GetConversation';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './Profile/Auth0ProviderWithHistory';
import LandingPage from './LandingPage/components/LandingPage';
import ApolloWrapper from './ApolloWrapper';
import { ToastProvider } from "./components/ui/toast"
import { Toaster } from "./components/ui/toaster"

import UserProfileLayout from './ProfilePage/UserProfileLayout';

function App() {

    return (
        <BrowserRouter>
            <ToastProvider>
                <Auth0ProviderWithHistory>
                    <Routes>
                        <Route exact path="/" element={
                            <LandingPage />
                        }
                        />
                        <Route path="/userprofile/" element={
                            <ApolloWrapper element={
                                <UserProfileLayout />
                            } />}
                        />
                        <Route exact path="/scenario/:id" element={
                            <ApolloWrapper element={
                                <Overworld />
                            } />}
                        />
                        <Route path="/location/:id" element={
                            <ApolloWrapper element={
                                <Location />
                            } />}
                        />
                        <Route path="/conversation/:id" element={
                            <ApolloWrapper element={
                                <Conversation isChat={false} />
                            } />}
                        />
                        <Route path="/chat/:id" element={
                            <ApolloWrapper element={
                                <Conversation isChat={true} />
                            } />}
                        />
                    </Routes>
                </Auth0ProviderWithHistory>
                <Toaster />
            </ToastProvider>
        </BrowserRouter>
    );
}

//Elves 65eccd03635456e5173ad235
//NeoTokyo 65ff27fb6e43ac4559f147fc
//Pemberley 66004b13006cd24fff3ea55a
//Fishing Village 6603768518e6fc04d166200e
//Androids 660392f318e6fc04d16621ed
//Atlantis 66044d8e18e6fc04d16623c2
//Spooksville 662543e842a92a6aaba97c15
//Tortuga 662555b4a6e2b73baeb97e5e
//Swoleville 66256508a6e2b73baeb98052
//Athens 6625aacca6e2b73baeb98131

export default App;

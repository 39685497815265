import { gql, useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import ScenarioList from '../ProfilePage/ScenarioList';

const GET_USERSCENARIOS = gql`
    query User
    {
        user
        {
            success
            user 
            {
                scenarios
                {
                    _id
                    name
                    seed
                    status
                    currentDateTime
                    description
                }
            }
        },
    }`

const UserScenarios = (props) => {

    const { loading, error, data } = useQuery(GET_USERSCENARIOS, {
        variables: { userId: props.userId }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <ScenarioList scenarios={data.user.user.scenarios} />
        )
    }
}

export default UserScenarios;
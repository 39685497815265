import React from 'react';
import { Plus } from 'lucide-react';
import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { useToast } from "../components/ui/use-toast";
import { useMutation } from "@apollo/client";
import { CREATE_SCENARIO_REQUEST } from '../Queries/CreateScenarioRequest';

const CreateScenarioForm = () => {
    const [scenarioText, setScenarioText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { toast } = useToast();

    const [createScenarioRequest] = useMutation(CREATE_SCENARIO_REQUEST);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!scenarioText.trim()) return;

        setIsLoading(true);

        try {
            console.log('Creating scenario:', scenarioText);

            // Assuming createScenarioRequest is passed as a prop or available in context
            const response = await createScenarioRequest({
                variables: { scenarioDescription: scenarioText.trim() }
            });

            toast({
                title: "Success!",
                description: "Your scenario has been created.",
                variant: "success",
            });

            setScenarioText('');
        } catch (error) {
            console.error('Error creating scenario:', error);

            toast({
                title: "Error",
                description: "Failed to create scenario. Please try again.",
                variant: "destructive",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Plus className="w-5 h-5 text-blue-500" />
                    Create A New Scenario
                </CardTitle>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <Textarea
                        value={scenarioText}
                        onChange={(e) => setScenarioText(e.target.value)}
                        placeholder="Enter your scenario description..."
                        className="h-32 resize-none"
                    />
                    <div className="flex justify-end">
                        <Button
                            type="submit"
                            disabled={!scenarioText.trim() || isLoading}
                            className="flex items-center gap-2"
                        >
                            {isLoading ? (
                                <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
                            ) : (
                                <Plus className="w-4 h-4" />
                            )}
                            {isLoading ? 'Creating...' : 'Create!'}
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

export default CreateScenarioForm;
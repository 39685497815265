import React from 'react';
import Emoji from './Emoji';
import CharacterLabel from '../Agent/CharacterLabel';

const OverworldCharacter = (props) => {

    // Safely access the filename with optional chaining
    const iconUrl = props.agent.agentDescription?.resizedIconFilename
        ? `https://dyifmflum502e.cloudfront.net/${props.agent.agentDescription.resizedIconFilename}`
        : '/api/placeholder/128/224'; // Fallback placeholder image

    return (
        <div
            style={{
                position: 'absolute',
                left: props.x,
                top: props.y,
                width: 128 * (props.widthMultiplier || 1),
                height: 224 * (props.heightMultiplier || 1)
            }}>
            <img
                src={iconUrl}
                width={128 * (props.widthMultiplier || 1)}
                height={224 * (props.heightMultiplier || 1)}
                style={{ position: 'absolute', zIndex: 4 }}
                alt={props.agent.name || 'Character'}
                onError={(e) => {
                    //e.target.src = '/api/placeholder/128/224';
                    e.target.alt = 'Missing Character';
                }}
            />
            <Emoji
                padding='12px'
                agent={props.agent} />
            <CharacterLabel
                agent={props.agent}
                buttonSize='btn-sm'
                width={128 * (props.widthMultiplier || 1)}
                height={224 * (props.heightMultiplier || 1)} />
        </div>
    );
}

export default OverworldCharacter;
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import currentConfig from './config';

const CreateApolloClient = (getAccessTokenSilently) => {
    const httpLink = createHttpLink({
        uri: `${currentConfig.pixelValleyGraphQLUrl}`,
    });

    const authLink = setContext(async (_, { headers }) => {
        try {
            const token = await getAccessTokenSilently();
            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                },
            };
        } catch (error) {
            console.error("Error fetching token: ", error);
            return { headers };
        }
    });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        fetchOptions: {
            mode: 'no-cors'
        },
    })
};

export default CreateApolloClient;

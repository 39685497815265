import React from "react";
import LoginButton from "./LoginButton";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";

export const ProfileButton = (props) => {

    const {
        user,
        isAuthenticated,
    } = useAuth0();

    const navigate = useNavigate();

    return (
        <div>
            {!isAuthenticated && (
                <LoginButton />
            )}
            {isAuthenticated && (
                <UncontrolledDropdown inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                        <img
                            src={user.picture}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                            width="50"
                            onClick={() => navigate(`/userprofile/`)}
                        />
                    </DropdownToggle>
                </UncontrolledDropdown>
            )}
        </div>
    );
};

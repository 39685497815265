import React from "react";
import { ProfileButton } from "../../Profile/ProfileButton";

export const Navigation = (props) => {
    return (
        <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-32">
                    {/* Logo/Brand Section */}
                    <div className="flex-shrink-0">
                        <a
                            href="#page-top"
                            className="text-4xl font-bold text-gray-800 hover:text-gray-600"
                        >
                            PIXEL VALLEY
                        </a>
                    </div>

                    {/* Navigation Items */}
                    <div className="flex items-center space-x-8">
                        <a
                            href="#features"
                            className="text-2xl text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md font-medium"
                        >
                            FEATURES
                        </a>
                        <a
                            href="#portfolio"
                            className="text-2xl text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md font-medium"
                        >
                            PLAY!
                        </a>
                        <a
                            href="#contact"
                            className="text-2xl text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md font-medium"
                        >
                            CONTACT
                        </a>
                        <div className="ml-4">
                            <ProfileButton />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;